<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>
      <template #card>
        <div class="">
          <div class="text-center">
            <h1> Using Authentication App </h1>
          </div>
          <div class=" pt-1">
            <p class="m-0 p-0">
              1. You need to install an authenticator app
            </p>
            <p class="m-0 p-0">
              2. Scan the QR code below with your authenticator app.
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">

          <!--                 this QR Code that will go when APIs are connected-->
          <QRCode />
        </div>
        <div class="">
          <validation-observer ref="authenticateForm" #default="{invalid}">
            <b-form @submit.prevent="verify">
              <!--                username -->
              <b-form-group label-for="code">
                <validation-provider
                    #default="{ errors }"
                    name="Code"
                    vid="code"
                    rules="required"
                >
                  <div class="exxtra-input">

                    <input id="login-username" v-model="code" type="number"
                           placeholder="* * * * *"
                    >
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <b-button
                    class="py-1"
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                >
                  Continue
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
import QRCode from '@/assets/svg/QRCode.svg';
import EmailIcon from "@/assets/svg/emailIcon.svg";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import { required } from "@core/utils/validations/validations";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";
import AuthenticatorAppIcon from "@/assets/svg/AuthenticationAppIcon.svg";
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    SecurityLogo,
    AuthWrapper,
    AuthenticatorAppIcon,
    EmailIcon,
    BottomDesign,
    QRCode,
    TextMessageIcon,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      authentication_method: '',
      loading: false,
      code: '',
      // validation rules
      required
    }
  },
  computed: {

  },
  methods: {
    verify() {}
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
