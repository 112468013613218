<template>
  <b-img
    fluid
    src="@/assets/images/logo/job.jpeg"
    alt="Login V2"
    :width="width"
  />
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    width: {
      type: Number,
      default: 200
    }
  },
  
  data() {
    return {
      imgUrl: '@assets/images/logo/exxtra.png',
    }
  },
}
</script>
